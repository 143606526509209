import { ActionType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import ListItem from './index';

const ListItemAction: FC<ListItemProps<ActionType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ActionType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem displayActionId={false} doc={doc} index={index} onClick={onClick}>
      <div className="flex-auto flex flex-col mx-3 space-y-1">
        <strong className="whitespace-nowrap">{doc.trigger}</strong>
      </div>
    </ListItem>
  );
};

export default ListItemAction;
